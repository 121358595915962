.booking-review {
  &__block {
    background-color: $ui-01;
    padding: 0 $spacing-06 $spacing-06 $spacing-06;
    &.with-border {
      border-bottom: 1px solid $ui-03;
    }
  }
}
.booking-edit-btn {
  text-transform: capitalize;
}
