@import './overrides.scss';
.offer-prices {
  text-align: right;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__gross {
    @include carbon--type-style('body-long-01');
    text-decoration: line-through;
  }
  &__final-gross {
    @include carbon--type-style('productive-heading-05');
  }
  &__sup {
    @include carbon--type-style('productive-heading-03');
    font-weight: 500;
  }
  &__discount {
    text-decoration: underline;
    color: $text-03;
    font-size: 0.9rem;
    &:hover {
      text-decoration: none;
      color: $text-03;
    }
  }
  .no-booking-info {
    color: $support-03;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__expired {
    width: 100%;
  }
}
