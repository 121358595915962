.choose-btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.choose-btn-wrapper a {
  text-decoration: none;
}
