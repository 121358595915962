.icon-status__active {
  fill: $interactive-01;
}

.icon-status__category-simple-status {
  height: 5px;
  width: 5px;
  background-color: $icon-02;
  border-radius: 50%;
}

.icon-status__untouched-item-status {
  color: $icon-02;
  fill: $icon-02;
}
