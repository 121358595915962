@import './overrides.scss';

.booking-inquiries-modal {
  &__premium {
    @include productive-h3-text-01;
    min-width: 7rem;
  }
  &__product-name {
    @include carbon--type-style('body-short-01');
    color: $text-01;
  }
  &__insurer {
    display: flex;
    align-items: center;
    &-name {
      @include productive-h3-text-01;
    }
  }
  &__logo {
    margin-right: $spacing-04;
    text-align: center;
    width: 20%;
    img {
      max-width: 100%;
    }
  }
  &__content {
    width: 80%;
  }
  &__tariff-name {
    @include carbon--type-style('body-short-01');
    color: $text-02;
  }
}
