.result-offer-name-skeleton {
  height: rem(40px);
  width: rem(100px);
}

.result-offer-prices-skeleton {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;

  .action-button {
    position: absolute;
    right: $spacing-05;
    bottom: $spacing-05;
  }
}

.skeleton-checkbox {
  width: rem(80px);
}
