.comparison-table-column {
  .bx--data-table-content {
    overflow: hidden;
  }
  @media screen and (max-width: 768px) {
    .bx--data-table-content,
    .comparison-table-content {
      overflow-x: auto;
    }
    .comparison-table-content {
      .bx--row {
        flex-wrap: nowrap;
      }
    }
  }
}
