.product-custom-design-toggle {
  margin-top: $spacing-01;
}

.product-custom-design-toggle-b2c {
  margin-top: $spacing-03;
}

.product-b2c-column-wrapper {
  display: flex;
  flex-direction: row;
  min-width: 1024px;
}

.product-b2c-column-product {
  min-width: 30%;
}

.product-b2c-column-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.product-b2c-column-action {
  margin-left: $spacing-08;
}
