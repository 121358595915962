@import './overrides';

.pra-modal-table {
  min-width: $spacing-10 * 14;
  &__product-name {
    @include carbon--type-style('productive-heading-01');
    margin-bottom: $spacing-03;
  }
}

.pra-modal-table tbody tr td,
.pra-modal-table thead tr th {
  background-color: $ui-02 !important;
}

.pra-modal-table tbody tr td {
  vertical-align: top;
  padding-top: $spacing-06;
  padding-bottom: $spacing-06;
}

.pra-modal-table tbody tr td:first-child,
.pra-modal-table tbody tr td:last-child {
  padding-left: $spacing-05;
}

.pra-modal-table thead tr {
  height: $spacing-07;
}

.pra-modal-table thead tr th {
  border-bottom: 1px solid $ui-03 !important;
}

.pra-modal-product-icon {
  height: $spacing-08 * 2;
  width: $spacing-08 * 2;
}
