.questionnaire-page__header {
  @include carbon--breakpoint-up(md) {
    position: sticky;
    top: 0.1px;
    z-index: $questionnaire-header-z-index;
  }
}

.questionnaire-step__skeleton {
  text-align: center;
}
