.booking-form-generator {
  .bx--accordion__item {
    margin-bottom: $spacing-03;
    background-color: $ui-01;
    border: none;
  }
  .bx--accordion__content {
    padding-left: $spacing-06;
    padding-right: $spacing-06;
    padding-top: 0;
    margin-top: -$spacing-05;
  }
  .bx--accordion__heading {
    padding: $spacing-05 0;
  }
  .bx--accordion__title {
    margin-left: $spacing-06;
    @include carbon--type-style('productive-heading-03');
    color: $text-02;
  }
  .configureInsurerEmail {
    .bx--file__selected-file {
      background-color: $ui-background;
    }
  }
  .configureConfirmationEmailInsurerDocs,
  .configureCustomerEmailInsurerDocs {
    .bx--accordion__title {
      @include carbon--type-style('productive-heading-03');
    }
  }
  .bx--text-input,
  .bx--dropdown {
    background-color: $ui-02;
  }
}
