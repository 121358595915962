.product-details-wrapper {
  display: flex;
  align-items: flex-end;
}

.product-details-title {
  color: $text-01;
  @include carbon--type-style('productive-heading-01');
}

.product-details-example ul {
  list-style-type: disc;
  margin: 0;
  padding-left: 0;
}

.product-details-example ul li svg {
  display: none;
}

.product-details ul li {
  margin-bottom: $spacing-03;
  position: relative;
  color: $text-01;
  @include carbon--type-style('body-long-01');
}

.product-details-info,
.product-details-info p {
  color: $text-01;
  @include carbon--type-style('body-long-01');
}

.product-info-details_section {
  flex: 1;
}

.product-details-check-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: $spacing-03 0;
  color: $text-01;
  @include carbon--type-style('body-long-01');
}

.product-details-check-wrapper li {
  list-style-type: none;
  svg {
    display: none;
  }
}

.product-details-checkmark {
  margin-right: $spacing-04;
  align-self: flex-start;
  margin-top: $spacing-01;
  min-width: $spacing-04;
}
