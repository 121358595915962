// overrides styles applied to svg by _reboot.scss
svg.bx--loading__svg {
  vertical-align: inherit;
}

// _date-picker.scss sets
// font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace
// to date picker. We want to override it and use font-family which is default for the application
.bx--date-picker__input {
  font-family: inherit;
}

// TODO: remove when the bug is fixed by carbon https://github.com/carbon-design-system/carbon/issues/9511
.bx--skeleton,
.bx--skeleton__text,
.bx--skeleton__placeholder {
  &:before {
    top: 0;
    left: 0;
  }
}

// IE10+ CSS here
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .bx--modal-container {
    display: flex;
    flex-direction: column;

    .bx--modal-content {
      height: 100%;
    }
  }
}
