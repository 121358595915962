@import './overrides.scss';
.result-filters {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .reset-btn {
    padding: 0;
    margin-bottom: $spacing-05;
    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
  @media only screen and (max-width: 768px) {
    overflow-x: auto;
  }
}
@media (min-width: 66rem) {
  .result-filters {
    display: block;
  }
}
