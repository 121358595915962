.info-header {
  .bx--btn--tertiary {
    color: $inverse-01;
    border-color: $inverse-01;
  }
  .bx--tooltip--a11y::before {
    animation: none;
    display: none !important;
  }
  .bx--modal-content {
    padding-right: $spacing-05;
  }
  .bx--structured-list-td {
    padding-bottom: $spacing-03;
    padding-top: $spacing-03;
    vertical-align: middle;
  }
}
