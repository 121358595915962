.table {
  margin-bottom: 0 !important;
}
.document-table-row {
  background-color: $ui-02;
}
div.document-table-row-item {
  text-align: center;
  padding: $spacing-02;
}
td.document-table-data {
  width: $spacing-03 * 31;
  background-color: $ui-02;
  border-right: $spacing-02 solid $ui-01;
}
