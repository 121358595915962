.n-and-p-modal-content-category {
  &-description {
    color: $text-02;
    @include carbon--type-style('body-long-01');
    margin-bottom: $spacing-05;
  }

  .n-and-p-modal-category-table-container div table tbody tr td,
  .n-and-p-modal-category-table-container div table thead tr th,
  .n-and-p-modal-category-table-container div table tbody tr:hover {
    background-color: $ui-01;
  }

  .n-and-p-modal-category-table-container div,
  .n-and-p-modal-category-table-container table {
    overflow: visible;
  }

  .n-and-p-modal-category-table-container div table thead tr th {
    border-bottom: 1px solid $ui-03;
  }

  .n-and-p-modal-category-table-container div table tbody tr:last-child td {
    border-bottom: none;
  }
}
