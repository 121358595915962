section #subheader-block {
  margin-bottom: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

section #subheader-block .header-bg-video {
  height: 430px;
  margin: 0 auto;
}

#subheader-block h1 {
  margin-top: 36px;
}

section #subheader-block ul li {
  font-size: 1.5rem;
}

.bg-white-opacity {
  background-color: rgba(255, 255, 255, 0.6);
}

.page-item {
  a.page-link {
    display: block;
    background-color: transparent;
    border: solid 1px grey;
    margin-left: 0;
    color: grey;
    border-right: none;
  }
}

.static-min-height {
  min-height: 650px;
}

.home {
  &__header {
    &-image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: bottom;
      background-position-x: center;
      height: 100%;
    }
    &-wrapper {
      height: 650px;
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0;
    }
    &-video {
      position: relative;
      padding-top: 56.25%;
    }
    &-player {
      position: absolute;
      top: -40px;
      left: 0;
      min-width: 1100px;
    }
    &-content {
      ul {
        padding-left: 0;
        li {
          list-style: none;
          svg {
            color: $secondary;
          }
        }
      }
    }
    &-confirmation {
      color: $confirmation-text-gray;
      margin-top: 0.7rem;
      margin-bottom: 0.5rem;
      line-height: 22px;
      &-link {
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }
  &__sub-header {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 99%;
    line-height: 1.5;
  }
  &__content {
    &-row {
      overflow: hidden;
    }
    .offset-5 {
      margin-left: 0;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
    .row {
      margin-right: -10px;
      margin-left: -10px;
    }
    img {
      max-width: 100%;
    }
  }
  &__iframe {
    border-radius: 5px;
    box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.25);
  }
}
.search-bar {
  background-color: $white;
  border-radius: 5px;
  padding: 1rem;
  &__wrapper {
    position: absolute;
    top: 50px;
    width: 100%;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    z-index: 2;
    box-shadow: 0 7px 16px rgba(0, 0, 0, 0.25);
  }
  &__extra-option {
    color: $body-color;
    padding: 5px 10px;
    background-color: #dcdcdc;
    position: absolute;
    bottom: -34px;
    left: 0;
    width: 100%;
    font-family: Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    border: 1px solid rgb(221, 221, 221);
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: left;
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.25);
    &:hover {
      color: $secondary;
      background-color: $bg-gray-btn !important;
    }
    &:active {
      background-color: #c3c3c3 !important;
    }
  }
  &__button {
    color: $white;
    padding: 1rem;
    border-radius: 5px;
    min-width: 126px;
    height: 58px;
    line-height: 0.8;
    &:focus {
      background-color: $secondary !important;
      border-color: $secondary !important;
    }
    &.btn-light {
      background-color: $gray-btn;
      border-color: $gray-btn;
      color: $white;
      cursor: auto !important;
      &:hover,
      &:active,
      &:focus {
        background-color: $gray-btn !important;
        border-color: $gray-btn !important;
        color: $white !important;
      }
    }
    &:disabled {
      background-color: $gray-btn !important;
      border-color: $gray-btn !important;
      color: $white !important;
      &:hover,
      &:active,
      &:focus {
        background-color: $gray-btn !important;
        border-color: $gray-btn !important;
        color: $white !important;
      }
    }
  }
  &__select {
    width: 60%;
  }
  &__menu-list {
    position: static !important;
  }
  &__option {
    &:last-child {
      background-color: #dcdcdc;
      position: absolute;
      bottom: -38px;
    }
    &--is-focused {
      background-color: $bg-gray-btn !important;
    }
    &--is-selected {
      background-color: #c3c3c3 !important;
    }
    &:active {
      background-color: #dcdcdc !important;
    }
  }
  &__add-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $bg-gray-btn;
    margin-top: 15px;
    margin-left: 10px;
    border-radius: 5px;
    padding: 9px 15px;
    font-size: 0.875rem;
  }
  &__input-wrapper {
    width: 50%;
    margin-top: 0.3rem;
    min-width: 12rem;
  }
  &--align {
    height: 58px;
    display: flex;
    align-items: center;
  }
}
.slider {
  padding: 20px 10px;
}
.slide {
  height: 30px;
  margin: 0 20px;
}

.landing-body-bg {
  background-color: $landing-body-bg;
}
.landing-box-bg {
  background-color: $landing-header-box-bg;
}
.landing-box-text {
  color: $landing-header-box-text;
}
.search-icon {
  margin-right: 0.5rem;
  margin-top: 1.3rem;
}

@media only screen and (max-width: 1150px) {
  .home {
    &__header {
      &-video {
        padding-top: 0;
        height: 100%;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .home {
    &__sub-header {
      background-image: none !important;
    }
  }
}
