// imitate the Container of Bootstrap
.container--custom {
  background-color: $ui-background;
  max-width: 1200px;
  width: 100%;
  padding-right: $spacing-05;
  padding-left: $spacing-05;
  margin: 0 auto;
}

.hidden {
  display: none;
}
