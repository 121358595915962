.insurer-icon-for-print {
  display: none;
}
.insurer-icon {
  display: block;
  margin-bottom: $spacing-04;
}
.gray-filter {
  filter: grayscale(100%);
}
.opacity-5 {
  opacity: 0.5;
}
@media print {
  .insurerIcon {
    display: none;
  }
  .insurer-icon-for-print {
    display: block;
  }
}
.final-gross {
  @include carbon--type-style('productive-heading-04');
}
.tariff-name {
  @include carbon--type-style('body-long-01');
  margin-bottom: $spacing-02;
}
.tendering-offer-tag {
  padding: $spacing-05;
}
