.booking {
  @media screen and (max-width: 768px) {
    .bx--progress {
      overflow-x: auto;
      padding-bottom: $spacing-06;
    }
  }

  .bx--progress-step {
    flex-grow: 1;
    svg {
      z-index: 0;
    }
  }
  .bx--progress-line {
    width: 100%;
  }
  .bx--progress-step-button--unclickable {
    cursor: default;
  }
}
