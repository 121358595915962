// Variable overrides
$enable-responsive-font-sizes: true;
$body-color: #4c536f;

$btn-padding-y: 8px;
$btn-padding-x: 16px;
$btn-border-radius: 0;
$btn-padding-y-sm: 4px;
$btn-padding-x-sm: 16px;

$btn-text: #212529;
$error-page-title-dark: #161616;
$error-page-text-grey: #525252;
$error-page-text-blue: #037cc2;
$confirmation-text-gray: #3b454b;
$info: #aaa;
$border-gray: #eee;
$dark-gray: #333;
$light-gray-bg: #f6f6f6;
$gray-font: #bbbfcb;
$gray-btn: #a5a9b4;
$bg-gray: #fafafa;
$th-bg-gray: #ecf0f5;
$row-gray: #f6f8fa;
$banner-bg: #4c536e;
$danger-title: #a94442;
$dash-border: #ddd;
$bg-ribbon: #71799d;
$bg-gray-btn: #f4f4f4;
$product-title: #4d526e;

$link-color: $secondary;
$link-hover-color: $primary;

$container-max-widths: (
  xl: 1200px,
);

// TODO: separate bootstrap modules
$dark: #000;
