.b2b-success {
  &__content {
    padding-top: $spacing-08;
    padding-bottom: $spacing-12;
  }
  &__wrapper {
    max-width: rem(732px);
    margin: 0 auto;
  }
  &__icon {
    text-align: center;
  }
  &__title {
    @include carbon--type-style('productive-heading-04');
    margin-top: $spacing-02;
  }
  &__sub-title {
    @include carbon--type-style('body-short-02');
    margin-top: $spacing-03;
  }
  &__list-title {
    @include carbon--type-style('productive-heading-02');
    margin-top: $spacing-06;
    margin-bottom: $spacing-03;
  }
  &__back-btn:hover {
    text-decoration: none;
  }
  &__list {
    margin-bottom: $spacing-04;
    @include carbon--type-style('body-short-01');

    &-number {
      height: $spacing-02 * 5;
    }
  }
  &__list-item {
    color: $text-02;
    display: flex;
    align-items: center;
    margin-bottom: $spacing-03;
    svg {
      align-self: flex-start;
      margin-right: $spacing-01;
      flex-shrink: 0;
      color: $icon-01;
    }
  }
  &__list-item::before {
    display: none;
  }
  &__success-icon {
    color: $interactive-01;
  }
}
