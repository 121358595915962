.circle-icon {
  background-color: white;
  border-radius: $spacing-06;
  width: $spacing-06;
  height: $spacing-06;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.payment-period {
  font-size: 0.875rem;
  margin-bottom: $spacing-04;
  @include type-style('body-long-01');
}

.sticky {
  z-index: 1;
  background-color: $ui-02;
  border-bottom: $spacing-02 solid $ui-01;
  .insurer-info-section {
    display: block;
  }
}

.insurer-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  height: $spacing-08;
  width: 100%;
}

.insurer-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  min-width: auto;
  padding: 0.9rem $spacing-04;
}

.modal-form-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;
  height: 100%;
}

.comparison {
  &__footer {
    padding: $spacing-04;
    border-top: $spacing-02 solid $ui-02;
  }
}

.print-img {
  max-width: $spacing-08 * 5;
  text-align: center;
  margin: 0 auto;
}

.icon-green-check {
  color: $inverse-support-02;
  font-size: 1.1rem;
  margin-left: 0.9rem;
}

.close-btn {
  font-size: 1rem;
  font-weight: bold;
}

.only-differences {
  &__text {
    max-width: $spacing-08 * 5;
  }
}

.current-insurance-form {
  color: $ui-02;
}
