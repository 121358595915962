.custom-autosuggest {
  &__container {
    position: relative;
    width: 100%;
    &--open {
      .search-bar__extra-option {
        display: block;
      }
    }
  }
  &__input {
    width: 100%;
    height: 50px;
    padding: 10px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 1rem;
    border: none;
    &:hover {
      cursor: text;
    }
    &:focus {
      outline: none;
    }
    &--focused {
      outline: none;
      ~ div.not-empty .react-autosuggest__suggestions-container {
        display: block;
      }
    }
    &--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &__suggestions-container {
    display: none;
    &--open {
      display: block;
      position: static;
      top: 50px;
      width: 100%;
      background-color: #fff;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid rgb(221, 221, 221);
      z-index: 2;
      display: block;
      max-height: 300px;
      overflow-y: auto;
    }
  }
  &__list-container {
    display: block;
    position: static;
    top: 50px;
    width: 100%;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid rgb(221, 221, 221);
    z-index: 2;
    display: block;
  }
  &__suggestions-list {
    position: relative;
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 0.875rem;
    max-height: 300px;
    overflow-y: auto;
    &.loading {
      overflow-y: hidden;
      &::after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
      }
      .list-loader {
        display: flex;
      }
    }
  }
  &__suggestion {
    cursor: pointer;
    padding: 5px 10px;
    font-size: 1rem !important;
    &:hover {
      color: $secondary;
      background-color: $bg-gray-btn !important;
    }
    &--highlighted {
      background-color: $bg-gray-btn !important;
    }
    &:active {
      background-color: #c3c3c3 !important;
    }
  }
}
.list-loader {
  list-style: none;
  text-align: center;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
