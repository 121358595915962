.booking-controlled-select {
  &__price {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: $text-02;
    align-items: center;
    margin-top: $spacing-06;
    &__premiums {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &-annual {
        @include carbon--type-style('productive-heading-02');
      }
      &-frequency {
        @include carbon--type-style('body-long-01');
      }
    }
    &-text {
      @include carbon--type-style('productive-heading-02');
    }
  }
}
