.product-page-actions {
  display: flex;
  flex-direction: column;
  > button {
    margin-bottom: $spacing-05;
  }
}

.product-info-header {
  & > button {
    margin-left: $spacing-05;
  }
  #tooltipIcon {
    position: absolute;
  }

  .bx--tooltip--definition .bx--tooltip__trigger {
    @include carbon--type-style('body-long-01');
    color: gray;
    border-bottom: none;
  }
}

@media only screen and (min-width: 768px) {
  .product-page-actions {
    flex-direction: row;
    margin-bottom: $spacing-06;
    > button {
      margin: 0 $spacing-05 0 0;
    }
  }
}
