.result-table {
  .expanded-table {
    &__row {
      position: relative;
    }

    & > .bx--data-table-content {
      overflow-y: hidden;

      table {
        height: 1px;
      }
    }
  }

}
