.prepare-rist-analisys-container {
  .bx--modal .bx--pagination,
  .bx--modal .bx--pagination__control-buttons,
  .bx--modal .bx--text-input,
  .bx--modal .bx--text-area,
  .bx--modal .bx--search-input,
  .bx--modal .bx--select-input,
  .bx--modal .bx--dropdown,
  .bx--modal .bx--dropdown-list,
  .bx--modal .bx--number input[type='number'],
  .bx--modal .bx--date-picker__input {
    background-color: $ui-01;
  }
}
