.questionnaire-field-with-info-box label {
  width: 100%;
}

.questionnaire-label-with-info-box {
  display: flex;
  justify-content: space-between;

  &__label {
    @include carbon--type-style('label-02');
    margin-right: $spacing-03;
  }

  &__icon {
    flex-shrink: 0;
  }
}

.questionnaire-info-box-container {
  height: 100%;

  @include carbon--breakpoint-down(md) {
    position: relative;
  }
}

.questionnaire-info-box {
  position: absolute;

  @include carbon--breakpoint-down(md) {
    width: 100%;
  }
}

.questionnaire-info-box__content {
  background-color: $ui-01;
  padding: $spacing-04 $spacing-06;
  border-left: 1px solid $ui-04;

  &,
  p {
    color: $text-02;
    @include carbon--type-style('body-long-01');
  }

  * {
    font-weight: revert;
    margin-bottom: revert;
    padding: revert;
  }

  @include carbon--breakpoint-down(md) {
    word-wrap: break-word;
  }
}

.questionnaire-info-box__caret-patch {
  width: $spacing-03;
  height: 100%;
  position: absolute;
  top: 0;
  left: 1px;
  background-color: $ui-01;
  z-index: 1;
}

.questionnaire-info-box__caret {
  position: absolute;
  width: $spacing-05;
  height: $spacing-05;
  left: -$spacing-03;
  border-top: 1px solid transparent;
  border-bottom: 1px solid $ui-04;
  border-left: 1px solid $ui-04;
  border-right: 1px solid transparent;
  background-color: $ui-01;
  transform: rotate(45deg);
}

.questionnaire-info-box__hover-bridge {
  position: absolute;
  height: 100%;
}
