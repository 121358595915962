.menu {
  padding: $spacing-04 0;

  .bx--accordion__item {
    border: none !important;
  }
}

.questionnaire-area-hidden .questionnaire-area {
  visibility: hidden;
}
