.product-badge-loading {
  width: $spacing-12;
}

.product-badge-wrapper {
  display: flex;
  align-items: center;

  .product-badge-info {
    display: flex;
    flex-direction: column;

    .product-badge-info-title {
      color: $text-02;
      @include carbon--type-style('label-01');
    }

    .product-badge-info-value {
      color: $text-01;
      @include carbon--type-style('productive-heading-01');
    }
  }
}
