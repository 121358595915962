.btn-secondary {
  color: $white;
  &:hover,
  &:active,
  &:focus {
    background-color: $third;
    border-color: $third;
    color: $white;
  }
  &:disabled {
    &:hover {
      background-color: $secondary !important;
      border-color: $secondary !important;
      color: $btn-text;
    }
  }
}

.btn-sm {
  font-weight: bold;
}

.btn-popup-opener {
  border-radius: 3px;
  width: 150px;
}

.btn-radius {
  border-radius: 3px;
}

.no-btn {
  border: none;
  background: none;
}
.btn-old-tariff {
  line-height: 1;
  width: 100%;
  color: $btn-text;
  word-wrap: break-word;
}
.btn-insurer-block {
  line-height: 1;
  color: $btn-text;
}

.zip-btn {
  width: 139px;
  text-align: center;
}
.btn-header {
  padding: 1px 10px;
  border: 1px solid;
  border-color: $white;
  border-radius: 4px;
  color: $white;
  margin-left: 0.5rem;
  &:hover {
    border-color: #ccc;
    text-decoration: none;
    cursor: pointer;
  }
}

.btn-product {
  height: 40px;
  font-size: 14px;
  font-family: 'Inter';
}

.btn-product svg {
  margin-right: 10px;
}

.btn-info {
  border: 1px solid $primary !important;
  background-color: white !important;
  margin-right: 8px;
  height: 40px;
  width: 40px;

  > svg {
    color: $primary;
  }
}

.btn-info-expanded {
  border: 1px solid $primary !important;
  background-color: $primary !important;
  margin-right: 8px;
  height: 40px;
  width: 40px;

  > svg {
    color: white;
  }
}

.btn-action-next {
  border: 1px solid #cccccc !important;
  background-color: white !important;
  height: 48px;

  > svg,
  span {
    color: $primary;
    height: 13px;
  }

  > span {
    font-size: 14px;
  }
}
