@import url('//fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700');
$primary: #004f7c;
$secondary: #8cc3d7;
$third: #3b454b;
$fourth: #004f7c;
$fifth: #eceeef;
$body-bg: #edeef0;
$landing-body-bg: #ffffff;
$orange: #eb5c37;
$landing-header-box-bg: rgba(255, 255, 255, 0.8);
$landing-header-box-text: #004f7c;
$font-family-sans-serif: 'PT Sans Narrow', sans-serif;
