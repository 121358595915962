.pra-modal-broker-info-container {
  .pra-modal-broker-info {
    display: flex;
    flex-direction: row;
    > div:first-of-type {
      margin-right: $spacing-06;
    }
  }

  .pra-modal-broker-info-section {
    display: flex;
    flex-direction: column;
    flex: 1;

    > * {
      margin-top: $spacing-05;
      flex: none;
    }
  }
}
