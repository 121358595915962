.tariff-details-modal {
  .bx--modal-container .bx--modal-header,
  .bx--modal-container .bx--modal-content,
  .bx--modal-container .bx--modal-content__regular-content {
    padding-right: $spacing-05;
  }
  .tariff-details .bx--tab-content {
    padding: 0 $spacing-05;
  }
}
