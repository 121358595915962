.booking {
  &__checkbox-error {
    margin-top: $spacing-03;
    display: flex;
    &-icon {
      color: $support-01;
      align-items: center;
    }
    &-text {
      margin-left: $spacing-03;
      color: $support-01;
      @include carbon--type-style('label-01');
    }
  }
}
