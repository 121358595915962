.editable-area {
  display: block;

  .hide-editor {
    display: none;
  }

  $first-row-height: rem(48px);
  $row-height: rem(18px);
  $toolbar-height: rem(49px);

  @for $i from 1 through 10 {
    .rows-#{$i} .tox.tox-tinymce {
      min-height: $first-row-height + (($i - 1) * $row-height + $toolbar-height + $spacing-01);
    }
  }

  &__error-message {
    color: $support-01;
    font-size: 0.75rem;
  }

  .tox.tox-tinymce {
    border: none;

    .tox-editor-container {
      border-bottom: $ui-04 1px solid;

      .tox-editor-header {
        height: $toolbar-height;

        .tox-toolbar-overlord {
          background: none;
          height: 100%;

          .tox-toolbar__primary {
            background: none;
            padding-bottom: $spacing-01;
            height: 100%;

            .tox-toolbar__group {
              background-color: $ui-02;
              padding: $spacing-01 $spacing-03;

              .tox-tbtn {
                margin: $spacing-01;
              }
              .tox-tbtn:hover,
              .tox-tbtn--enabled,
              .tox-tbtn--enabled:hover {
                background-color: $hover-light-ui;
                border: none;
                border-radius: 0;
              }
            }

            .tox-toolbar__group:not(:last-of-type) {
              margin-right: $spacing-01;
              border: none;
            }
          }
        }
      }
    }
  }
}

.tox .tox-dialog-wrap .tox-dialog {
  max-width: 510px;
}

.tox.tox-tinymce-aux {
  z-index: #{z('modal') + 1};
}
