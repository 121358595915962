@import './overrides.scss';
.booking-success {
  &__icon {
    text-align: center;
    margin-bottom: $spacing-05;
    svg {
      color: $interactive-01;
    }
  }
  &__title {
    @include carbon--type-style('productive-heading-03');
    margin-bottom: $spacing-09;
    text-align: center;
    color: $text-02;
  }
  &__text {
    @include carbon--type-style('body-long-02');
    color: $text-02;
    display: flex;
    align-items: center;
    padding-bottom: $spacing-04;
    svg {
      margin-right: $spacing-03;
      flex-shrink: 0;
    }
  }
  &__wrapper {
    max-width: rem(700px);
    margin: 0 auto;
    padding: $spacing-06 0 $spacing-09;
  }
  &-documents {
    background-color: $ui-01;
    padding: $spacing-07;
    margin-top: $spacing-05;
    &__title {
      @include productive-h3-text-01;
      margin-bottom: $spacing-05;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__text {
      @include carbon--type-style('body-long-01');
      color: $text-02;
      display: flex;
      align-items: center;
    }
    &__accordion-item {
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
    }
    &__loading {
      min-height: rem(34px);
      display: flex;
      align-items: center;
      padding-left: $spacing-03;
    }
  }
  &-nps {
    background-color: $interactive-01;
    color: $inverse-01;
    padding: $spacing-07;
    margin-top: $spacing-05;
    display: flex;
    &__content {
      padding-right: 3rem;
      &-title {
        @include carbon--type-style('productive-heading-02');
      }
      &-text {
        margin: 1rem 0rem;
      }
      &-button {
        background-color: $inverse-01;
        color: $interactive-01;
      }

      @media screen and (max-width: 768px) {
        padding-right: 0;
      }
    }
    &__image {
      img {
        width: rem(174px);
        height: rem(134px);

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}
