.custom-datepicker {
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: $third;
  }
  .rdtPicker td.rdtToday:before {
    border-bottom-color: $third;
  }
  .rdtPicker {
    border: 1px solid $info;
  }
  &.datepicker-input-error {
    input {
      border: 1px solid $danger;
      &:focus {
        border: 1px solid $danger;
      }
    }
  }
}

.card-deck {
  margin: 0;
  margin-bottom: 10px;
}

.card {
  margin: 0 !important;
  border-radius: 0;
  margin-right: 10px !important;
  &:last-child {
    margin-right: 0 !important;
  }
}

.modal-header {
  border: none;
  padding-bottom: 0;
}

.modal-body {
  padding-top: 0;
}

.modal-footer {
  border: none;
}

.modal__form {
  border-bottom: 1px solid $info;
}

.save-modal {
  &__info-icon {
    font-size: 0.8rem;
  }
  &__btn {
    align-self: baseline;
    &:disabled {
      // background-color: $white;
      // color: $primary;
      // border: 1px solid $light-gray-bg;
      // opacity: 1;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.input-error {
  border: 1px solid $danger;
  &:focus {
    border: 1px solid $danger;
  }
}

sup {
  top: -0.5em;
  font-size: 0.55em;
  font-weight: normal;
}

.white-space {
  white-space: nowrap;
}

.height-0 {
  height: 0;
}

.height-100vh {
  height: 100vh;
}

.back-link {
  margin-left: 10px;
}

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.table {
  table-layout: fixed;
  color: $body-color;
}

.table .thead-light th {
  padding: 6px 6px 6px 15px;
  color: $body-color;
  background-color: $body-bg;
  border: none;
}

.print-icon {
  font-size: 1.5em;
  line-height: 1.5rem;
}

.check-icon {
  font-size: 1.5rem;
}

.info-icon {
  background-color: #e8e9ed;
  display: inline-block;
  border-radius: 1.875rem;
  width: 30px !important;
  height: 30px;
  padding: 0 5px;
  line-height: 30px;
  font-size: 0.9375rem;
  text-align: center;
  &:hover {
    color: $third;
  }
}

.tooltip-inner {
  background-color: $secondary;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: $secondary;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: $secondary;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: $secondary;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: $secondary;
}

.spacer {
  background-color: $body-bg;
}

.gray-filter {
  filter: grayscale(100%);
}

.opacity-5 {
  opacity: 0.5;
}

.form-control {
  &::placeholder {
    color: $info;
    font-size: 0.9rem;
  }
}

.col-px-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-dark-gray {
  color: $dark-gray;
}

.w-88px {
  width: 88px;
}
.Toastify__toast--info {
  background-color: $primary !important;
}
.bg-opacity {
  background-color: rgba(0, 0, 0, 0.1);
}
.input-error-text {
  font-size: 0.875rem;
  color: $danger;
}
.modal {
  &__input {
    &::placeholder {
      color: #a5a5a5;
      font-size: 0.875rem;
    }
  }
  &__label {
    font-size: 0.875rem;
    // min-height: 72px;
  }
  &__icon {
    font-size: 0.9rem;
    width: 20px;
    height: 28px;
    background-color: transparent;
  }
}
.text-14 {
  font-size: 0.875rem;
}
.text-white {
  a {
    color: $white;
  }
}
@media (max-width: 1199px) {
  .modal-w {
    max-width: 90%;
  }
}
