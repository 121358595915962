@import './overrides.scss';

.booking-selected-tariff {
  margin-bottom: 0;
  &__info {
    display: flex;
    &-wrapper {
      margin-right: $spacing-05;
    }
  }
  &__logo {
    margin-right: $spacing-03;
    text-align: center;
    width: 20%;
    img {
      max-width: 100%;
    }
  }
  &__content {
    width: 80%;
  }
  &__name {
    @include carbon--type-style('productive-heading-01');
  }
  &__product-name {
    @include carbon--type-style('body-short-01');
    margin-bottom: $spacing-05;
  }
  &__sum {
    display: flex;
    justify-content: space-between;
    background-color: $ui-background;
    padding: $spacing-05;
    @include carbon--type-style('productive-heading-01');
    color: $text-02;
    text-align: right;
  }
  &__btn {
    text-align: right;
    margin-top: $spacing-06;
  }
  &__premium {
    white-space: nowrap;
    text-align: right;
    font-weight: bold;
  }
}
