.product-category {
  display: inline-flex;
  align-self: center;
  align-items: center;
  background-color: $ui-01;
  padding: 4px 12px;
  border-radius: 20px;
  overflow: hidden;
}

.product-category-rank-name {
  color: $text-02;
  @include carbon--type-style('body-short-01');
  white-space: nowrap;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .product-category {
    align-items: center;
    padding: 5px 12px;

    img {
      height: 18px;
    }
  }
}
