@import './overrides.scss';

.result-table {
  .offer {
    &__row {
      position: relative;
      &--expandable {
        td {
          padding: 0;
        }
      }
    }
    &__sum {
      @include carbon--type-style('body-long-01');
    }
    &__td {
      &--name,
      &--highlights,
      &--prices {
        height: 100%;
      }
      &--highlights {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: $spacing-05;
      }
    }
  }

  .offer-prices__sup {
    vertical-align: baseline;
  }

  .offer-highlight__text button.bx--tooltip__trigger.bx--tooltip__trigger--definition {
    border: none;
    text-decoration: underline;
    text-decoration-style: dotted;
  }

  .offerDetails {
    min-width: $spacing-10 * 2;
    font-size: 0.75rem;
  }
}
