@import './overrides';

.risk-assessment-modal-light {
  background-color: $ui-background;
}

.risk-assessment-modal-subtitle {
  @include carbon--type-style('productive-heading-02');
  color: $text-02;
}
