@import './overrides.scss';
.offer-highlight {
  display: flex;
  align-items: flex-start;
  &__text {
    @include carbon--type-style('caption-01');
  }
  &__ul {
    padding-bottom: $spacing-05;
  }
}
