.error-page {
  font-family: 'Inter', sans-serif;
  max-width: 768px;
  padding: 4rem;
  height: 100vh;
  &__title {
    color: $error-page-title-dark;
    font-weight: 400;
  }
  &__text {
    color: $error-page-text-grey;
    line-height: 1.3;
  }
  &__code {
    color: $error-page-text-blue;
  }
}
