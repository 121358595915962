#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  padding: 10px 25px 10px 15px !important;
}

%contact {
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 0.9375rem;
}

header .h5 {
  font-size: 1.2rem;
  font-weight: bold;
  @extend %contact;
}

header .h4 {
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.8rem;
  @extend %contact;
}

header .h6 {
  font-size: 1.15rem;
  @extend %contact;
}

footer {
  margin-top: auto;
  padding: 0.25rem 2rem;
  background-color: $primary;
}
.info-h3 {
  font-size: 1.625rem;
  line-height: 1.1;
  padding: 20px 20px 20px 30px;
  margin: 0;
}
@media print {
  .info-h3 {
    color: $black;
  }
  @page {
    margin: 50px;
  }
}

.top-bar {
  padding-right: 25px;
  font-size: 0.875rem;
  a:hover {
    color: $white;
  }
}

.no-image img {
  display: none;
}

.ts-modal {
  overflow-x: hidden;
  overflow-y: auto;
  color: $body-color;
}
