.product-more-accordion-item > div {
  padding: 0 !important;
}

.product-more-accordion-item > div div:last-child {
  border: none !important;
}

.product-more-insurance-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(224, 224, 224, 0.5);
  padding: $spacing-05 $spacing-05 $spacing-05 0;
  width: 100%;
}

.product-more-insurance-row > span {
  @include carbon--type-style('productive-heading-01');
  color: $text-02;
}
