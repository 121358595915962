.product-b2c-info-details {
  color: $text-01;
}

.product-b2c-details-wrapper {
  display: flex;
}

.product-b2c-details-title {
  color: $text-01;
  @include carbon--type-style('productive-heading-01');
}

.product-b2c-details ul {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.product-b2c-details-example ul {
  list-style-type: disc;
  margin: 0;
  padding-left: 0;
}

.product-b2c-details-example ul li svg {
  display: none;
}

.product-b2c-details ul li {
  margin-bottom: $spacing-03;
  position: relative;
  color: $text-01;
  @include carbon--type-style('body-long-01');
}

.product-b2c-details-info,
.product-b2c-details-info p {
  color: $text-01;
  @include carbon--type-style('body-long-01');
}

.product-b2c-info-details_section {
  flex: 1;
}
