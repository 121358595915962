.card {
  border: 1px solid $ui-03;
}
.headers {
  padding: $spacing-03;

  .icon {
    display: inline-block;
    width: 100%;
  }
  .icon-text {
    text-align: center;
  }
}
