.customer-info-form {
  &__questions {
    margin-bottom: $spacing-07;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__buttons-actions {
    display: flex;
    flex-direction: row;

    > button:first-child {
      margin-right: 1px;
    }
  }
  &__menu-item {
    @include carbon--type-style('productive-heading-01');
    color: $text-02;
  }

  &__questions-row {
    display: flex;
    flex-direction: column;

    > div {
      flex: 1;
      margin-bottom: $spacing-06;
    }
  }
}

// 672px is small size from break point useWindowSize() hook
@media only screen and (min-width: 672px) {
  .customer-info-form__questions-row {
    flex-direction: row;

    > div:first-child {
      margin-right: $spacing-06;
    }
  }
}
