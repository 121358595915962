//** PRODUCT B2C **//

.product-wrapper {
  font-family: Inter;
}

.product-table {
  font-size: 14px;
  min-width: 1120px;
}
.product-table thead tr th {
  color: #525252;
  border-top: none;
}

.product-table thead tr th:nth-child(1) {
  width: 30%;
}

.product-table thead tr th:nth-child(2) {
  width: 50%;
}

.product-table thead tr th:nth-child(3) {
  width: 20%;
}

.product-table tbody tr td {
  padding: 16px 12px 8px;
}

.product-table tbody tr td:first-child {
  padding-left: 16px;
}

.product-table tbody tr td:last-child {
  padding-right: 16px;
}

.product-details ul {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.product-details ul li {
  margin-bottom: 0.5rem;
}

.product-details ul li svg {
  margin-right: 0.5rem;
  font-size: 12px;
  color: $interactive-01;
}

.product-details_custom ul {
  color: black;
}

.product-details.product-details_custom ul {
  padding-left: 24px;
}

.product-details ul li {
  margin-bottom: 0.5rem;
  position: relative;
}

.product-details ul li svg {
  left: -24px;
  position: absolute;
  top: 5px;
}

.product-image {
  border-radius: 50%;
  background-color: #f4f4f4;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
}

.product-image img {
  width: 55%;
}

.product-category {
  background-color: #f4f4f4;
  padding: 3px 12px;
  border-radius: 20px;
  overflow: hidden;
}

.product-category p {
  text-transform: lowercase;
}

.product-name {
  color: #525252;
}

//** PRODUCT B2B **//

.g-bnet-icon {
  width: 16px;
  height: 16px;
}

.product-accordion-b2b {
  overflow: auto;
}

.product-table-b2b {
  font-size: 14px;
  min-width: 1120px;
}
.product-table-b2b thead tr th {
  color: #525252;
  border-top: none;
}

.product-table-b2b thead tr th:nth-child(1) {
  width: 30%;
}

.product-table-b2b thead tr th:nth-child(2) {
  width: 25%;
}

.product-table-b2b thead tr th:nth-child(3) {
  width: 15%;
}
.product-table-b2b thead tr th:nth-child(4) {
  width: 10%;
}
.product-table-b2b thead tr th:nth-child(5) {
  width: 20%;
}

.product-table-b2b tbody tr td {
  padding: 16px 12px 0;
}

.product-table-b2b tbody tr td:first-child {
  padding-left: 16px;
}

.product-table-b2b tbody tr td:last-child {
  padding-right: 16px;
}

.product-info-collapse-toggle {
  right: 0;
}

.product-info-collapse-toggle button svg {
  height: 28px !important;
  width: 24px !important;
  color: $interactive-01;
}

.riskoanalyse-actions button {
  width: 100%;
  min-height: 48px;
  height: auto;
  text-align: start;

  > svg {
    display: none;
  }
}

.riskoanalyse-actions button:first-child {
  margin-bottom: 16px;
}

@media only screen and (min-width: 768px) {
  .riskoanalyse-actions button svg {
    display: inline-block;
  }
}

@media only screen and (min-width: 1130px) {
  .riskoanalyse-actions {
    flex-direction: row !important;
  }
  .riskoanalyse-actions button {
    width: auto;
  }
  .riskoanalyse-actions button:first-child {
    margin: 0 16px 0 0 !important;
  }
}

// IE10+ CSS here
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .product-image,
  .product-category {
    align-items: center;
  }
  .product-image img {
    height: 55%;
  }

  .product-category {
    padding: 5px 12px;
  }
  .product-category img {
    height: 18px;
  }
}
