.controlled-file-uploader .bx--file-browse-btn {
  max-width: 100%;
}

.controlled-file-uploader--light .bx--file__selected-file {
  background-color: $ui-background;
}

.controlled-file-uploader--disabled .bx--file__selected-file {
  opacity: 0.7;
  pointer-events: none;
}
