@import './overrides';

.menu-item__button {
  @include button-reset;

  position: relative;
  display: flex;
  width: 100%;
  min-height: rem(40px);
  flex-direction: $accordion-flex-direction;
  align-items: flex-start;
  justify-content: $accordion-justify-content;
  padding: rem(10px) 0;
  margin: 0;
  color: $text-01;
  cursor: pointer;
  transition: background-color motion(standard, productive) $duration--fast-02;

  svg {
    flex: 0 0 auto;
  }

  &:hover::before,
  &:focus::before {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: calc(100% + 2px);
    content: '';
  }

  &:hover {
    background-color: $ui-03;
  }

  &:focus {
    outline: none;
  }
}

.menu-item__content {
  display: flex;
  width: 100%;
  padding-left: $spacing-05;
  align-items: center;
}

.menu-item__title {
  @include type-style('body-long-01');

  z-index: 1;
  width: 100%;
  margin: $accordion-title-margin;
  text-align: left;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &--icon {
    padding-left: $spacing-03;
  }
}

.menu-item__indent-1 {
  padding-left: $spacing-06 !important;
}

.menu-item__indent-2 {
  padding-left: $spacing-06 * 2 !important;
}
