.product-B2C-icon-wrapper {
  background-color: $interactive-01;
  height: 108px;
  width: 108px;
  display: flex;
  justify-content: center;
  align-items: center;

  .product-B2C-icon {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(336deg) brightness(109%) contrast(300%);
    height: 50%;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .product-B2C-icon-wrapper {
    .product-B2C-icon {
      height: 50px;
    }
  }
}
