.steps .page-item a.page-link,
.steps .page-item span.page-link {
  display: inline-block;
  font-size: 1.125rem;
  padding: 7px 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  margin-left: 3px;
  border: solid 1px;
  border-radius: 0 !important;
  background-color: $white;
  pointer-events: none;
}

.steps .page-item.active span.page-link {
  color: $white;
  z-index: 0;
}

.steps .page-item.disabled span.page-link {
  background-color: $white;
  color: #c6c6c6;
  border: solid 1px #c6c6c6;
}

.steps {
  padding-top: 7px;
  padding-bottom: 7px;
}

.steps div a svg,
.steps div span svg {
  vertical-align: -0.125em !important;
}

.steps {
  .link-icon_skeleton {
    width: 16px;
    height: 16px;
    display: inline-block;
  }

  .link-pagination_skeleton {
    width: 32px;
    height: 32px;
  }
}

.step-title {
  padding-bottom: 7px;
}
