@import '@developers/graphite/scss';

.ts-app {
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

  // Required
  @import 'node_modules/bootstrap/scss/functions';

  @import 'clientVariablesCMS.scss';
  @import 'clientVariables.scss';

  // Bootstrap for search profession modal
  @import 'node_modules/bootstrap/scss/variables';
  @import 'node_modules/bootstrap/scss/mixins';
  @import 'node_modules/bootstrap/scss/modal';

  .ts {
    line-height: normal;

    @import 'node_modules/bootstrap/scss/bootstrap';
    @import 'layout.scss';
    @import 'loader.scss';
    @import 'steps.scss';
    @import 'btn.scss';
    @import 'shared.scss';
    @import 'header.scss';
    @import 'footer.scss';
    @import 'professionModal.scss';
    @import 'autosuggestCustomTheme.scss';
    @import 'pages/homePage.scss';
    @import 'pages/dealbreakPage.scss';
    @import 'pages/errorPage.scss';
    @import 'pages/comparisonPage.scss';
  }
}
