@media print {
  .selectContract {
    display: none;
  }
}
.contract-button {
  padding-right: $spacing-04;
  margin-top: $spacing-03;
  cursor: pointer;
}
.bx--btn {
  min-height: 34px;
}
.discount-section {
  min-height: 34px;
}
.icon-right-angle {
  margin-top: $spacing-01;
  margin-left: $spacing-04;
}
.insurer-block {
  .no-booking-info {
    color: $support-03;
  }
}
