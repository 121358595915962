.result-own-discount {
  .bx--date-picker.bx--date-picker--single {
    width: 100%;
  }
  .bx--date-picker.bx--date-picker--single .bx--date-picker__input {
    width: 100%;
  }
  .bx--date-picker-container {
    width: 100%;
  }
  .first-row.bx--row {
    align-items: flex-end;
  }
}
