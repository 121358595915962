.generate-link-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.generate-link-modal-content > svg:first-of-type {
  color: $interactive-01;
  margin-top: $spacing-04;
  width: 100%;
  height: rem(56px);
}

.generate-link-modal-text {
  margin: $spacing-06 0 $spacing-06;
  @include carbon--type-style('productive-heading-04');
  color: $text-02;
}

.generate-link-modal-input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 $spacing-06;

  svg {
    color: white;
  }
}

.generate-link-modal-copy-btn-copied,
.generate-link-modal-copy-btn-copied:hover {
  background-color: $interactive-02;
}

.generate-link-modal-copy-btn {
  min-width: $spacing-08 * 3;
}
