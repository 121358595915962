.riskoanalyse-modal {
  background-color: $ui-background;

  .riskoanalyse-modal-cat-name {
    @include carbon--type-style('productive-heading-01');
    margin-bottom: $spacing-05;
  }

  .riskoanalyse-modal_row {
    background-color: $ui-01;
    padding: $spacing-05;
    margin-bottom: $spacing-06;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .riskoanalyse-modal-question {
    display: flex;
    align-items: center;
  }

  .riskoanalyse-modal-question-field {
    flex: none;
  }
}
