@import './overrides.scss';
.booking-form-generator {
  &__title {
    @include productive-h3-text-01;
    margin-top: $spacing-01;
  }

  &__main-title {
    @include productive-h3-text-01;
    margin-bottom: $spacing-07;
  }

  &__form-block {
    background-color: $ui-01;
    padding: $spacing-06;
    margin-bottom: 0;
    margin-top: $spacing-03;
  }

  &__form-accordion-block {
    background-color: $ui-01;
  }

  .useLogoUpload {
    padding: $spacing-06;
    margin-bottom: $spacing-03;
  }

  .blockConfigureCoverNote {
    padding: $spacing-01 $spacing-06 $spacing-06;
    margin-bottom: $spacing-03;
    margin-top: 0;
  }

  .configureCustomerEmailCheckbox {
    background-color: $ui-02;
    padding: 0;
    margin-bottom: $spacing-04;
  }

  .configureCustomerEmailDocs {
    padding: $spacing-02 $spacing-06;
    margin-top: 0;

    .booking-form-generator__sub-title {
      margin-top: $spacing-01;
    }
  }

  .configureConfirmationEmail {
    padding: $spacing-06 $spacing-06 $spacing-01 $spacing-06;
  }

  .reviewAndConfirmCheckboxes {
    padding: $spacing-02;
  }
}
