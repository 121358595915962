.offer-prices {
  .bx--modal-container {
    text-align: left;
  }
  &__discount {
    &.bx--btn--ghost {
      text-decoration: underline;
      padding: 0;
      min-height: auto;
      &:hover {
        background-color: transparent;
        text-decoration: none;
      }
    }
  }
  &__wrapper {
    .bx--modal-content {
      padding-right: 1rem;
    }
  }
}
