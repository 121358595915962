.result-filters-skeleton {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: $spacing-04;
  .filter-skeleton {
    min-width: 20%;
  }

  @media (min-width: 66rem) {
    display: block;
  }
}
