@import './overrides.scss';
.customer-info-section__notification {
  width: 100%;
  margin-bottom: $spacing-07;
}

.customer-info-section__skeleton {
  @extend .customer-info-section__notification;
  margin-top: $spacing-03;
}

.tender-request-menu {
  @extend .questionnaire__right-column;
  background-color: $ui-01;
  margin-bottom: $spacing-06;
}
