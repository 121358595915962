.pra-modal-existing-protection {
  margin-bottom: $spacing-05;
}

.pra-modal-existing-protection-skeleton {
  @extend .pra-modal-existing-protection;
  > span {
    height: $spacing-05 !important;
  }
}

.pra-modal-protection-wrapper {
  max-width: $spacing-06 * 6;
  min-width: $spacing-06 * 6;
  line-height: 0;

  .fake-checkbox-space {
    height: $spacing-04;
  }
}

.pra-modal-protection-wrapper .gds-currency-input:nth-last-child(1) {
  margin-bottom: 0;
}

.pra-modal-combo-box-wrapper {
  line-height: 0;
}
