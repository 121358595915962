.booking-selected-tariff {
  .bx--structured-list-row .bx--structured-list-td:first-of-type,
  .bx--structured-list-row .bx--structured-list-th:first-of-type,
  .bx--structured-list-td,
  .bx--structured-list-th {
    padding-left: 0;
    padding-right: 0;
  }
  .bx--structured-list-row.bx--structured-list-row--header-row {
    border-bottom: 0;
  }
  .bx--btn--ghost.bx--btn--sm {
    padding: 0;
  }
}
