td.table-cell {
  width: $spacing-04 * 20.5;
  background-color: $ui-02;
  border-right: $spacing-02 solid $ui-01;
}
.table-column-name {
  text-align: left;
  word-wrap: break-word;
  @include carbon--type-style('body-short-01');
}
.circle-icon {
  margin-left: auto;
  background-color: transparent;
}

div.table-column-bolt,
div.table-column-info {
  padding-right: $spacing-06;
  display: flex;
  align-items: center;

  #tooltip-div {
    display: flex;
    align-items: center;
  }
}
tr.table-row {
  background-color: $ui-02;
  border-bottom: $spacing-02 solid $ui-01;
}
