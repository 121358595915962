.offer-name {
  &__sales {
    .bx--tooltip__trigger.bx--tooltip__trigger--definition {
      border-bottom: none;
    }
    .bx--tooltip__trigger svg {
      fill: $ui-background;
    }
  }
}
