@import './overrides';

.relevant-products-cards {
  display: flex;
  flex-wrap: wrap;

  .relevant-product-card {
    width: 50%;
    padding: $spacing-03 0;

    &:nth-child(even) {
      padding-left: $spacing-03;
    }

    &:nth-child(odd) {
      padding-right: $spacing-03;
    }

    .relevant-product-card__tile {
      border: 1px solid $ui-03;

      &.relevant-product-card__tile-selected:not(:hover):not(.relevant-product-card__tile-disabled) {
        background-color: $ui-03;

        .relevant-product-card__name {
          color: $text-01;
        }
      }

      &.relevant-product-card__tile-disabled {
        .relevant-product-card__name {
          color: $disabled-03;
        }

        .relevant-product-card__icon {
          background-color: $disabled-02;
        }
      }
    }
  }
}

.relevant-product-card__content {
  display: flex;
  & > *:not(:last-child) {
    margin-right: $spacing-05;
  }
}

.relevant-product-card__icon {
  height: $spacing-10;
  width: $spacing-10;
}

.relevant-product-card__content-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.relevant-product-card__name {
  @include carbon--type-style('productive-heading-01');
  color: $text-02;
  word-break: break-word;
}
