@import './overrides.scss';
.offer-name {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__sales {
    @include carbon--type-style('label-01');
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    background-color: $interactive-01;
    color: $ui-background;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-04;
    padding: $spacing-02 $spacing-04;
  }
  &__company {
    @include carbon--type-style('productive-heading-01');
  }
  &__tariff {
    @include carbon--type-style('body-long-01');
  }
  &__logo {
    height: rem(44px);
  }
}
