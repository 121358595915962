.home-carbon {
  .bx--inline-notification {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
  .bx--inline-notification__text-wrapper {
    display: block;
  }
}
