.header-row {
  .header-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: $spacing-06 * 11;
    border-right: $spacing-02 solid $ui-01;
  }
  .button-all-tarifs {
    padding: $spacing-04;
    margin: auto;
  }
  .button-old-tarifs-text {
    margin-right: $spacing-06;
  }
  .icon {
    width: $spacing-03;
  }
}
.switch-button-section {
  display: flex;
  .switch-button {
    display: flex;
    margin-left: auto;
    .switch-text {
      margin-right: $spacing-04;
      margin-top: $spacing-05;
      @include carbon--type-style('body-short-01');
    }
    .toggle-button {
      margin-right: $spacing-05;
    }
  }
}
@media print {
  .button-all-tarifs {
    display: none;
  }
  .backLink {
    display: none;
  }
}
.print-icon {
  font-size: 1.5em;
  line-height: 1.5rem;
}

.invisible {
  display: none;
}

@media screen and (max-width: 768px) {
  .insurers-header {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
