.category-item__active {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  & .menu-item__button {
    background-color: $ui-03;
  }

  & .icon-status__category-simple-status {
    background-color: $interactive-01;
  }
}
