$questionnaire-header-z-index: 1000;

/** Input z-index overrides */
.bx--list-box__menu,
.flatpickr-calendar {
  z-index: $questionnaire-header-z-index - 1;
}

@import './questionnaire-page';
@import '../../questionnaire';
@import '../../Components/Menu/menu';
@import '../../Components/Menu/CategoryItem/category-item';
@import '../../Components/Menu/IconStatus/icon-status';
@import '../../Components/Menu/MenuItem/menu-item';
@import '../../Components/Menu/QuestionAnswerSetItem/qaset-item';
@import '../../Components/Menu/CategoryGroupObjectItem/category-group-object-item';
@import '../../Components/QuestionnaireArea/questionnaire-area';
@import '../../Components/QuestionnaireArea/questions';
@import '../../Components/QuestionnaireArea/RenderQuestion/DateQuestion/date-question';
@import '../../Components/QuestionnaireArea/RenderQuestion/CheckboxlistQuestion/checkbox-list-question';
@import '../../Components/QuestionnaireArea/RenderQuestion/CheckboxQuestion/checkbox-question';
@import '../../Components/QuestionnaireArea/RenderQuestion/ProfessionEntityQuestion/profession-entity-question';
@import '../../Components/QuestionnaireArea/RenderQuestion/SelectQuestion/select-question';
@import '../../Components/QuestionnaireArea/RenderQuestion/InfoBox/_questionnaire-field-with-info-box.scss';
@import '../../Components/ComboBoxWithSpecialItem/_combo-box-with-special-item.scss';
@import '../../Components/QuestionnaireArea/RenderQuestion/SelectWithOwnAnswerQuestion/_select-with-own-answer-input.scss';
@import '../../Components/AddObjectCategoriesModal/_add-object-categories.scss';
@import '../../Components/QuestionnaireArea/RenderQuestion/FileQuestion/file-question';
@import '../../Components/InfoMessage/_info-message.scss';
