.questionnaire-area {
  padding: $spacing-05 $spacing-06;
  min-height: rem(500px);
}

.questionnaire-area-row {
  position: relative;
}

.questionnaire-area__title {
  @include carbon--type-style('productive-heading-03');
  padding-bottom: $spacing-09;
  color: $text-01;
}

.questionnaire-area__category {
  margin-bottom: $spacing-08;

  .questionnaire-area__buttons {
    margin-bottom: $spacing-06;
  }
}

.questionnaire-area__category-title {
  @include carbon--type-style('productive-heading-02');
  padding-bottom: $spacing-06;
  color: $text-01;
}

.questionnaire-area__skeleton-text-area {
  height: rem(40px);
}

.questionnaire-area__skeleton-text-input {
  margin-top: $spacing-06;
}

.questionnaire-area__buttons {
  @include carbon--breakpoint-up(md) {
    display: flex;
    justify-content: flex-end;
  }

  > *:not(:first-child) {
    margin-left: 1px;
  }
}

.questionnaire-area__continue {
  height: rem(48px);
  max-width: 100%;

  @include carbon--breakpoint-down(md) {
    display: block;
    width: 100%;
  }
}

.questionnaire-area__buttons_custom {
  @extend .questionnaire-area__buttons;
  flex-direction: column;
}

@media (min-width: 860px) {
  .questionnaire-area__buttons_custom {
    justify-content: flex-start;
    flex-direction: row;
  }

  .questionnaire-area__continue {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
