.circle {
  background-color: #edeef0;
  border-radius: 24px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.payment-period {
  font-size: 0.875rem;
  margin-bottom: 10px;
  color: #888;
}

.insurers-header {
  min-width: 800px;
  .svg-inline--fa {
    vertical-align: middle !important;
  }
}

.gray-bg__title {
  background: #efefef;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.switch-btn {
  border-radius: 20rem !important;
  line-height: 0;
  .switch-handle {
    border-radius: 20rem;
    padding-top: 0px;
    padding-bottom: 0px;
    border-width: 0 1px;
    background-color: #fff;
  }
  .switch-on {
    text-align: left;
    line-height: 0;
    padding: 0.35rem 0.4rem 0.25rem 0.4rem !important;
    font-size: 0.875rem !important;
    line-height: 0.5 !important;
    border-radius: 0.2rem !important;
  }
  .switch-off {
    text-align: right;
    line-height: 0;
    padding: 0.35rem 0.4rem 0.25rem 0.4rem !important;
    font-size: 0.875rem !important;
    line-height: 0.5 !important;
    border-radius: 0.2rem !important;
  }
}

.sticky-table {
  &__wrapper {
    width: 100%;
  }
  &__inner {
    position: relative;
    min-width: 800px;
  }
}
.sticky-component {
  min-height: 0 !important;
  > div {
    transform: none !important;
  }
}

@media screen and (max-width: 800px) {
  .sticky-table__wrapper {
    overflow: auto;
  }

  .sticky {
    left: 15px;
  }
}
.insurer-info {
  &__h5 {
    font-weight: bold;
    margin-bottom: 0;
  }
}

.sticky {
  z-index: 1;
  background-color: $white;
  border-bottom: 1px solid $body-bg;
  .insurer-info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
    margin-top: 4px;
    &__h5 {
      font-size: 0.875rem;
      padding-right: 4px;
      line-height: 1.5;
    }
    &__final-gross {
      font-size: 0.875rem;
      padding-right: 4px;
    }
    .payment-period {
      margin-bottom: 0;
    }
  }
}
.insurer-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  height: 75px;
  width: 100%;
}
.insurer-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  min-width: auto;
  padding: 0.9rem 10px;
}
.modal-form-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;
  height: 100%;
}
.comparison {
  &__footer {
    padding: 12px;
    border-top: 3px solid $border-gray;
  }
}
.print-img {
  max-width: 200px;
  text-align: center;
  margin: 0 auto;
}
.icon-green-check {
  color: $success;
  font-size: 1.1rem;
  margin-left: 0.9rem;
}
.close-btn {
  font-size: 1rem;
  font-weight: bold;
}

.card {
  margin-bottom: 10px !important;
}
.icon-trash {
  font-size: 0.9rem;
}

.document {
  &__item {
    border-bottom: 1px dashed $dash-border;
    padding-bottom: 2px;
    padding-top: 2px;
    color: $primary;
    &:hover {
      opacity: 0.7;
    }
  }
}
.only-differences {
  &__text {
    max-width: 200px;
  }
}

.current-insurance-form {
  color: $body-color;
}
