.booking-warning-modal {
  &__body {
    @include carbon--type-style('body-long-01');
    p {
      margin-bottom: $spacing-05;
    }
    i {
      font-style: italic;
    }
  }
}
