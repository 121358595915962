.result {
  &__price-range {
    @include carbon--type-style('body-long-01');
  }
  &__page {
    background-color: $ui-background;
  }
  &__filters {
    &-wrapper {
      border-right: 1px solid $ui-03;
    }
  }

  &__button-spacing {
    margin-right: $spacing-01;
  }
}

.comparison-inline-loading {
  margin-left: $spacing-03;
  width: $spacing-01;
}
