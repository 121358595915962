.date-question__month-and-year-wrapper {
  display: flex;

  & > div {
    width: 100%;
  }

  & div:first-child {
    margin-right: $spacing-03;
  }
}

.date-question__month-and-year-label {
  margin-bottom: $spacing-03;
}
