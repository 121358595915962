.booking-review {
  &__block-data {
    display: flex;
    align-items: flex-start;
    margin-bottom: $spacing-03;
    color: $text-02;
  }
  &__block-name {
    @include carbon--type-style('body-short-01');
    width: 35%;
    margin-right: $spacing-06;
  }
  &__block-value {
    width: 65%;
    @include carbon--type-style('productive-heading-01');
  }
}
