.tariff-details {
  .bx--tab-content {
    background-color: $ui-01;
    padding: 0;
  }
  .bx--structured-list {
    margin-bottom: 0;
  }
  .bx--structured-list-td {
    padding-top: $spacing-03;
    padding-bottom: $spacing-03;
    &:first-child {
      width: 45%;
    }
  }
  .bx--structured-list-th {
    padding-top: $spacing-03;
    padding-bottom: $spacing-03;
    &:first-child {
      width: 45%;
    }
  }
}
