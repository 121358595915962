.bx--modal {
  .risk-assessment-modal-light {
    .bx--pagination,
    .bx--pagination__control-buttons,
    .bx--text-input,
    .bx--text-area,
    .bx--search-input,
    .bx--select-input,
    .bx--dropdown,
    .bx--dropdown-list,
    .bx--number input[type='number'],
    .bx--date-picker__input,
    .bx--multi-select {
      background-color: $ui-01;
    }
  }
}
