.tender-page__header {
  @include carbon--breakpoint-up(md) {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}

.tender-page__customer-info {
  .menu-item__content {
    padding-left: $spacing-05;
  }

  &__custom-menu {
    padding-top: 17px;
  }
}

.tender-page__customer-info__questionnaire {
  @extend .tender-page__customer-info;
  margin-bottom: $spacing-04;
}

.tender-page__questionnaire-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: $spacing-03;
}

@media only screen and (min-width: 672px) {
  .customer-info__menu {
    position: sticky;
  }
}

@media (min-width: 860px) {
  .tender-page__questionnaire-actions {
    margin-bottom: 0;
  }
}
