.table-data {
  display: flex;
  text-align: center;
  p {
    @include carbon--type-style('body-short-01');
  }
}
td.calculation-items {
  background-color: $ui-02;
}
.table-data-item {
  margin: auto;
  padding-top: $spacing-04;
  padding-bottom: $spacing-04;
}
