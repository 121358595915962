@import './overrides';

.controlled-file-uploader {
  font-size: 14px;
}

.controlled-file-uploader__title {
  display: block;
}

.controlled-file-uploader__error-wrapper {
  margin-top: $spacing-03;
  display: flex;
}

.controlled-file-uploader__error-icon {
  color: $support-01 !important;
  align-items: center;
}

.controlled-file-uploader__error-text {
  margin-left: $spacing-03;
  color: $support-01;
  @include carbon--type-style('label-01');
}
