.booking-questionnaire {
  &__header {
    margin-bottom: 2rem;
    margin-top: 0;
  }
  &__empty {
    display: flex;
    background-color: $ui-01;
    min-height: rem(400px);
    display: flex;
    justify-content: center;
    align-items: center;

    &-title {
      margin-bottom: 1rem;
      @include carbon--type-style('productive-heading-03');
    }
  }
}
