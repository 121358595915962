.info-message__container {
  background-color: $ui-01;
  width: 480px;
}

.info-message__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
}

.info-message__title {
  @include carbon--type-style('productive-heading-03');
  padding: $spacing-05;
}

.info-message__subtitle {
  @include carbon--type-style('body-long-01');
  padding: 0 $spacing-07 $spacing-09 $spacing-05;
}

.info-message__button {
  display: flex;
  justify-content: flex-end;
  color: $interactive-01;
}
