.n-and-p-modal-category-accordion > div div:last-child {
  border: none !important;
}

.n-and-p-modal-category-accordion {
  border-bottom: none !important;
}

.n-and-p-modal-category-accordion > div {
  width: 50%;
  padding-right: 0;
}
.n-and-p-modal-category-accordion-input {
  margin-top: $spacing-03 * 3;
  padding-right: $spacing-04;
}
