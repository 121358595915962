.dismissal-modal {
  &__logo {
    height: rem(40px);
    width: 40%;
  }
}
div#modal-body {
  padding: $spacing-05;
}
.calculators-grid {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-05;
  padding: 0;
}
.grid {
  padding: 0;
}
.calculators-cell {
  box-sizing: border-box;
  width: 31%;
  background-color: $ui-02;
  padding: $spacing-05;
  .item-name {
    @include carbon--type-style('productive-heading-01');
    color: $text-02;
    margin-top: $spacing-03;
  }
  .item-reason {
    @include carbon--type-style('body-long-01');
    color: $text-02;
    margin-top: $spacing-02;
  }
}

.calculator-tag-section {
  width: 60%;
  .calculator-tag {
    float: right;
  }
}

.toggle-small {
  width: 100%;
  margin-top: -$spacing-04;
  .toggle-button {
    float: right;
    margin-top: -$spacing-05;
  }
  .toggle-label {
    float: right;
    margin-right: $spacing-03;
    @include carbon--type-style('body-short-01');
    color: $text-01;
  }
}

.filter-calculations {
  display: none;
}
