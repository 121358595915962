.dealbreak {
  &__icon-border {
    border: 5px solid #d9d9d9;
    padding: 3px;
  }
  &__check-icon {
    font-size: 3em;
    line-height: 1.5rem;
    color: $green;
  }
  &__desc-title {
    margin-bottom: 0;
    font-weight: 700;
  }
}
.dealbreak-form__title {
  background: #efefef;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}
