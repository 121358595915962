.products-table {
  .bx--data-table td,
  .bx--data-table tbody th {
    padding-left: 0;
    padding-right: 0;
  }

  .product-info {
    display: flex;

    .product-info-product-icon {
      margin: $spacing-05;
    }

    &-data {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      &-upper {
        height: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: $ui-01 1px solid;

        &-right {
          display: flex;

          > * {
            margin-left: $spacing-05;
          }
        }
      }

      &-lower {
        height: 50%;
        display: flex;
        align-items: center;

        > * {
          margin: 0 $spacing-05;
        }
      }
    }
  }

  .product-custom-design-toggle {
    margin-top: $spacing-01;
  }

  .product-table-circle-icon {
    background: $ui-01;
    border-radius: 50%;
    padding: $spacing-03;

    width: $spacing-07;
    height: $spacing-07;
    line-height: $spacing-03;
    text-align: center;
    vertical-align: middle;
  }

  .product-table-l-separartor {
    border-left: $ui-01 1px solid;
    height: 100%;
  }
}
