.questions__field-wrapper {
  margin-bottom: $spacing-06;

  label {
    @include carbon--type-style('body-short-01');
    color: $text-01;
  }

  i {
    font-style: italic;
  }

  b {
    font-weight: bold;
  }
}

.questions__file-field-wrapper {
  margin-bottom: $spacing-06;
}

.questions__checkbox-error {
  margin-top: $spacing-03;
  display: flex;
}

.questions__checkbox-error-icon {
  color: $support-01 !important;
  align-items: center;
}

.questions__checkbox-error-text {
  margin-left: $spacing-03;
  color: $support-01;
  @include carbon--type-style('label-01');
}

.questions__read-only-label {
  @include carbon--type-style('body-short-01');
  color: $text-01;
  margin-bottom: $spacing-05;
  display: block;
}

.questions__read-only-text {
  @include carbon--type-style('productive-heading-01');
  color: $text-02;
}
