@import './overrides.scss';
.info-header {
  background-color: $interactive-01;
  color: $inverse-01;
  min-height: $spacing-10;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    @include carbon--type-style('productive-heading-04');
    text-transform: uppercase;
    word-break: break-word;
    word-wrap: break-word;
  }
  &__btns {
    display: flex;
    justify-content: flex-end;

    > div {
      display: flex;
      align-items: center;
    }
  }
}
