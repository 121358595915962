.pra-modal-rec-range {
  display: flex;
  flex-direction: row;
  margin-top: $spacing-05;
}

.pra-modal-rec-range-divider {
  margin: 0 $spacing-04;
  margin-top: $spacing-07;
}
