.profession-modal {
  &__second-column {
    min-width: 270px;
  }
  &__container {
    overflow: scroll;
  }
  &__wrapper {
    box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 10px;
    max-height: 530px;
  }
  &__group {
    margin: 0;
    padding: 0;
    max-height: 577px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    &::-webkit-scrollbar {
      border-left: none;
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      border-radius: 6px;
    }
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      line-height: 22px;
      padding: 9px 12px;
      border-bottom: 1px solid $bg-gray-btn;
      cursor: pointer;
      &:hover,
      &.active {
        background-color: $secondary;
        color: $white;
        svg {
          color: $white;
        }
      }
      // &.isDisabled {
      // 	cursor: not-allowed;
      // 	background-color: $gray-font;
      // 	&:hover,
      // 	&:active,
      // 	&:focus {
      // 		background-color: $gray-font;
      // 		color: $body-color;
      // 	}
      // }
    }
    &--left {
      max-height: 577px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  &__link-btn {
    padding-top: 1.5rem;
    width: 185px;
    font-size: 0.875rem;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}
.alphabetical-list {
  padding-left: 1rem;
  width: 25px;
  &__letter {
    color: #c4c7ca;
    font-size: 0.875rem;
    cursor: pointer;
    &:hover {
      color: $secondary;
      font-weight: bold;
    }
    &.active {
      color: $secondary;
      font-weight: bold;
    }
  }
}
