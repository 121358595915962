.booking-success {
  .bx--list--unordered > .bx--list__item::before {
    content: '';
  }
  .bx--accordion__content {
    padding-left: $spacing-08;
  }
  .bx--accordion__title {
    @include carbon--type-style('body-long-01');
    color: $text-02;
  }
  .bx--accordion__heading {
    display: flex;
    align-items: center;
  }
  .bx--btn--ghost.bx--btn--icon-only .bx--btn__icon path:not([data-icon-path]):not([fill='none']),
  .bx--btn--ghost.bx--btn--icon-only .bx--btn__icon {
    fill: $interactive-01;
  }
}
