.#{$prefix}--accordion--xl .menu-item__button,
.#{$prefix}--accordion--lg .menu-item__button {
  min-height: rem(48px);
}

.#{$prefix}--accordion--sm .menu-item__button {
  min-height: rem(32px);
  padding: rem(5px) 0;
  padding-right: rem(16px);
}
