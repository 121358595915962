.relevant-product-card .relevant-product-card__tile {
  padding-left: $spacing-09;
  padding-right: $spacing-05;

  .bx--tile__checkmark svg {
    fill: $ui-04;
  }

  &.relevant-product-card__tile-selected {
    .bx--tile__checkmark svg {
      fill: $icon-01;
    }

    &:hover .bx--tile__checkmark svg {
      fill: $icon-02;
    }
  }

  &.relevant-product-card__tile-disabled .bx--tile__checkmark svg,
  &.relevant-product-card__tile-disabled:hover .bx--tile__checkmark svg {
    fill: $disabled-03;
  }

  .bx--tile__checkmark {
    left: $spacing-05;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;

    .bx--tile__checkmark svg {
      position: absolute;
    }
  }
}
