.pra-modal-table-container {
  .bx--data-table .bx--list-box input[role='combobox'],
  .bx--data-table .bx--list-box input[type='text'],
  .bx--data-table .bx--dropdown,
  .bx--data-table .bx--list-box,
  .bx--data-table .bx--number input[type='number'],
  .bx--data-table .bx--number__control-btn::before,
  .bx--data-table .bx--number__control-btn::after,
  .bx--data-table .bx--text-input,
  .bx--data-table .bx--select-input {
    background-color: $ui-01;
  }

  .bx--data-table .bx--number__control-btn::before,
  .bx--data-table .bx--number__control-btn::after {
    display: none;
  }
}
