.select-products-modal {
  margin-top: $spacing-03;

  // TODO: remove padding-bottom when carbon issue is fixed
  // https://github.com/carbon-design-system/carbon/issues/9766 or
  // https://github.com/carbon-design-system/carbon/issues/9732
  padding-bottom: $spacing-10;

  & > *:not(:last-child) {
    margin-bottom: $spacing-07;
  }

  & > * > *:not(:last-child) {
    margin-bottom: $spacing-05;
  }
}
