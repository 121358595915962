.questionnaire {
  &--light-background {
    .questionnaire__section {
      background-color: $ui-background;
    }
  }

  .bx--modal {
    z-index: $questionnaire-header-z-index + 1;
  }
}

.questionnaire__section {
  background-color: $ui-01;
}

.questionnaire__left-column {
  @include carbon--breakpoint-down(md) {
    padding-bottom: $spacing-06;
  }
}

.questionnaire__left-column,
.questionnaire__menu {
  @include carbon--breakpoint-up(md) {
    width: rem(288px);
    min-width: rem(288px);
  }
}

.questionnaire__menu {
  position: sticky;
}

.questionnaire__right-column {
  @include carbon--breakpoint-up(md) {
    width: 100%;
  }
}

.questionnaire__content {
  display: flex;

  @include carbon--breakpoint-down(md) {
    flex-direction: column;
  }
}

.questionnaire__left-column,
.questionnaire__right-column {
  margin: 0 $spacing-05;
}
