@import './overrides.scss';
.booking {
  &__page {
    &-wrapper {
      padding: $spacing-09;

      @media screen and (max-width: 768px) {
        padding: $spacing-09 $spacing-01;
      }
    }
    &-body {
      min-height: rem(200px);
      // not possible to avoid !important in this case because inline styles need to be overridden
      .questionnaire__menu {
        top: 0 !important;
      }
    }
    &-nav {
      margin-bottom: $spacing-10;
    }
  }
  &__btns {
    &-group {
      text-align: right;
    }
  }
}
