.building-category {
  &-list {
    margin-left: $spacing-06;
    li {
      @include carbon--type-style('body-short-01');
      color: $text-02;

      &:before {
        @include carbon--type-style('productive-heading-01');
        color: $text-02;
      }
    }
    span {
      font-weight: bold;
    }
  }

  &-subtitle {
    margin-top: $spacing-05;
    color: $text-02;
    @include carbon--type-style('productive-heading-02');
  }

  &-fields > * {
    margin-top: $spacing-05;
  }
}
