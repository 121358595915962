@import './overrides';

.prepare-rist-analisys {
  background-color: $ui-02;
  max-width: $spacing-10 * 11;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .prepare-rist-analisys {
    max-width: $spacing-10 * 15;
  }
}
