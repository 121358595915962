.add-category-object__input {
  margin-top: $spacing-06;
}

.add-category-object__subtitle {
  @include carbon--type-style('body-long-01');
  color: $text-02;
  padding-top: $spacing-06;
  padding-bottom: $spacing-05;
  width: 80%;
}
