.booking-review {
  &__block-title {
    @include productive-h3-text-01;
    margin-bottom: $spacing-06;
  }
  &__block-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: $spacing-06;
    border-top: 1px solid $ui-03;
  }
  &__upper-block-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: $spacing-06;
  }
  &__col {
    &.booking-sticky {
      position: sticky;
      top: 0;
    }
  }
}
